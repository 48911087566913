import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { DecodedToken } from 'src/app/interfaces/interfaces';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public isLogged = new BehaviorSubject(false);
  public user_info = new BehaviorSubject<DecodedToken>(null);
  public isKidsDoctor = new BehaviorSubject(false);
  constructor() {}
}
